import { apiGatewayService } from './index';
import { AxiosResponse, AxiosError } from 'axios';

interface AIResponse {
  status: string;
  message?: string;
  error?: string;
}

interface IngestResponse extends AIResponse {
  ingested_count: number;
}

interface KnowledgeBaseItem {
  id: string;
  title: string;
  description: string;
  sourceName: string | null;
  createdAt: string;
}

interface QueryResponse extends AIResponse {
  text_answer: string;
  dropdown_answer: string | null;
  aiQueryResults: Record<string, { relevance_score: number }>;
  relevantKnowledgeBase?: KnowledgeBaseItem[];
}

// This interface should match what the backend expects
interface IngestRequestItem {
  id: string;
  title: string;
  description: string;
  source_name: string;
}

interface DeleteResponse extends AIResponse {
  deleted_count: number;
}

interface GenerateAnswerParams {
  question_id: string;
  organization_id: string;
  question_text: string;
  additional_info?: string;
  dropdown_options?: string[];
}

export const aiService = {
  // Ingest a single question to AI service
  ingestQuestion: async (questionData: IngestRequestItem): Promise<IngestResponse> => {
    try {
      console.log('Sending ingest request with data:', questionData); // Debug log

      const response: AxiosResponse<IngestResponse> = await apiGatewayService.request({
        method: 'POST',
        url: '/api/ai/ingest',
        data: {
          items: [
            {
              id: questionData.id,
              title: questionData.title,
              description: questionData.description,
              source_name: questionData.source_name
            }
          ]
        }
      });

      console.log('Raw AI service response:', response); // Debug log

      if (response.status >= 400) {
        throw new Error(response.data.error || 'Ingestion failed');
      }

      return response.data;
    } catch (error) {
      console.error('AI ingestion failed:', error);
      if (error instanceof AxiosError && error.response) {
        console.error('Error response:', error.response.data); // Debug log
      }
      throw error;
    }
  },

  // Query AI service for answer generation
  generateAnswer: async (params: GenerateAnswerParams): Promise<QueryResponse> => {
    try {
      const response: AxiosResponse<QueryResponse> = await apiGatewayService.request({
        method: 'POST',
        url: '/api/ai/query',
        data: params,
        timeout: 30000, // Increase timeout to 30 seconds
      });

      // Ensure the response includes both aiQueryResults and relevantKnowledgeBase
      return {
        ...response.data,
        aiQueryResults: response.data.aiQueryResults || {},
        relevantKnowledgeBase: response.data.relevantKnowledgeBase || []
      };
    } catch (error) {
      console.error('AI query failed:', error);
      throw error;
    }
  },

  // Update the delete method to handle multiple IDs
  deleteDocument: async (sourceIds: string | string[]): Promise<DeleteResponse> => {
    try {
      // Ensure we have an array of IDs
      const ids = Array.isArray(sourceIds) ? sourceIds : [sourceIds];
      
      console.log('Sending delete request for sources:', ids); // Debug log

      const response: AxiosResponse<DeleteResponse> = await apiGatewayService.request({
        method: 'POST',
        url: '/api/ai/delete',
        data: {
          source_ids: ids // Send as array
        }
      });

      console.log('Raw AI delete response:', response);

      if (response.status >= 400) {
        throw new Error(response.data.error || 'Deletion failed');
      }

      return response.data;
    } catch (error) {
      console.error('AI deletion failed:', error);
      if (error instanceof AxiosError && error.response) {
        console.error('Error response:', error.response.data);
      }
      throw error;
    }
  }
};