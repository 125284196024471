import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import questionnaireService from '../services/questionnaireService';
import { Workbook } from '@fortune-sheet/react';
import '@fortune-sheet/react/dist/index.css';
import type { WorkbookInstance } from '@fortune-sheet/react';
import { questionService } from '../services/questionService';

interface LoadingOverlayProps {
  message?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ message = 'Processing...' }) => (
  <div className="absolute inset-0 bg-white flex items-center justify-center z-50">
    <div className="flex flex-col items-center space-y-4">
      <div className="animate-spin rounded-full h-9 w-9 border-[2.5px] border-gray-200 border-t-gray-600"></div>
      <div className="text-base text-gray-600 font-normal">{message}</div>
    </div>
  </div>
);

interface MappingData {
  data: string | string[];
  excelLocation: {
    type: 'question' | 'answer' | 'dropdown' | 'additional';
    cells: string;
    worksheetId: string;
  };
}

interface FormattedQuestion {
  questionText: string;
  answerText: string | null;
  additionalInfo: string | null;
  dropdownOptions: string[] | null;
  excelLocation: {
    question?: { cells: string; worksheetId: string; type: string };
    answer?: { cells: string; worksheetId: string; type: string };
    dropdown?: { cells: string; worksheetId: string; type: string };
    additional?: { cells: string; worksheetId: string; type: string };
  };
  order: number;
}

interface MappedField {
  data: string | string[];
  excelLocation: {
    type: 'question' | 'answer' | 'dropdown' | 'additional';
    cells: string;
    worksheetId: string;
  };
}

export const QMapping: React.FC = () => {
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const workbookRef = useRef<WorkbookInstance>(null);
  const [workbookData, setWorkbookData] = useState<any[] | null>(null);
  const [questionData, setQuestionData] = useState<MappingData[]>([]);
  const [answerData, setAnswerData] = useState<MappingData[]>([]);
  const [dropdownData, setDropdownData] = useState<MappingData[]>([]);
  const [additionalInfoData, setAdditionalInfoData] = useState<MappingData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mappedQuestions, setMappedQuestions] = useState<MappedField[]>([]);
  const [formattedQuestions, setFormattedQuestions] = useState<FormattedQuestion[]>([]);

  useEffect(() => {
    const loadWorkbook = async () => {
      if (!questionnaireId) return;

      try {
        setIsLoading(true);
        const sheets = await questionnaireService.getWorkbook(questionnaireId);
        if (Array.isArray(sheets)) {
          setWorkbookData(sheets);
        } else {
          throw new Error('Invalid workbook data format');
        }
      } catch (error) {
        console.error('Failed to load workbook:', error);
        toast.error('Failed to load workbook data');
      } finally {
        setIsLoading(false);
      }
    };

    loadWorkbook();
  }, [questionnaireId]);

  const getMergedCellInfo = (ref: React.RefObject<WorkbookInstance>, row: number, col: number) => {
    const sheet = ref.current?.getSheet();
    if (!sheet?.data) return null;

    const cell = sheet.data[row]?.[col];
    if (!cell) return null;

    if (cell.mc) {
      const mainCell = sheet.data[cell.mc.r]?.[cell.mc.c];
      if (!mainCell) return null;

      return {
        isMerged: true,
        mainCellRow: cell.mc.r,
        mainCellCol: cell.mc.c,
        value: mainCell.v,
        mergeRange: {
          rs: cell.mc.rs,
          cs: cell.mc.cs
        }
      };
    }

    return {
      isMerged: false,
      value: cell.v
    };
  };

  const processQuestionRow = (ref: React.RefObject<WorkbookInstance>, rowIndex: number, selection: any) => {
    const sheet = ref.current?.getSheet();
    if (!sheet || !sheet.data) {
      console.log('No sheet data available');
      return null;
    }

    const questionParts = [];
    const coordinates = [];

    try {
      const startCol = selection.column[0];
      const endCol = selection.column[1];

      for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
        const cellInfo = getMergedCellInfo(ref, rowIndex, colIndex);
        
        if (!cellInfo) continue;

        if (cellInfo.isMerged && cellInfo.mergeRange) {
          const value = cellInfo.value;
          if (value) {
            questionParts.push(value.toString().trim());
            
            const numRepeats = cellInfo.mergeRange?.cs ?? 1;
            for (let i = 0; i < numRepeats; i++) {
              coordinates.push(`${rowIndex},${colIndex + i}`);
            }
          }
          
          colIndex += (cellInfo.mergeRange?.cs ?? 1) - 1;
        } else {
          if (cellInfo.value) {
            questionParts.push(cellInfo.value.toString().trim());
            coordinates.push(`${rowIndex},${colIndex}`);
          }
        }
      }

      if (questionParts.length === 0) {
        return null;
      }

      return {
        data: questionParts.join(' | '),
        cells: coordinates.join(' | ')
      };

    } catch (error) {
      console.error('Error in processQuestionRow:', error);
      return null;
    }
  };

  const processAnswerRow = (ref: React.RefObject<WorkbookInstance>, rowIndex: number, selection: any) => {
    const sheet = ref.current?.getSheet();
    if (!sheet || !sheet.data) {
      console.log('No sheet data available');
      return null;
    }

    try {
      const startCol = selection.column[0];
      let coordinates = `${rowIndex},${startCol}`;

      const cellInfo = getMergedCellInfo(ref, rowIndex, startCol);
      const value = cellInfo?.value || "";

      if (cellInfo?.isMerged && cellInfo.mergeRange) {
        const coordStr = `${rowIndex},${startCol}`;
        const numRepeats = cellInfo.mergeRange.cs || 1;
        const coordArray = Array(numRepeats).fill(coordStr);
        coordinates = coordArray.join(' | ');
      }

      return {
        data: value.toString(),
        cells: coordinates
      };

    } catch (error) {
      console.error('Error in processAnswerRow:', error);
      return null;
    }
  };

  const processDropdownRow = (ref: React.RefObject<WorkbookInstance>, rowIndex: number, selection: any) => {
    const sheet = ref.current?.getSheet();
    if (!sheet || !sheet.data) {
      console.log('No sheet data available');
      return null;
    }

    try {
      const startCol = selection.column[0];
      const endCol = selection.column[1];
      console.log('Processing dropdown at row:', rowIndex, 'columns:', startCol, '-', endCol);

      let dropdownFound = false;

      // Process each column in the range to find dropdown
      for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
        const cellInfo = getMergedCellInfo(ref, rowIndex, colIndex);
        if (!cellInfo) continue;
        
        // Get actual cell coordinates (handle merged cells)
        const actualRow = cellInfo.isMerged ? cellInfo.mainCellRow : rowIndex;
        const actualCol = cellInfo.isMerged ? cellInfo.mainCellCol : colIndex;

        // Check for dropdown validation in both possible locations
        const validationKey = `${actualRow}_${actualCol}`;
        const dataVerification = (sheet.config as any)?.dataVerification?.[validationKey] || 
                                (sheet as any)?.dataVerification?.[validationKey];

        if (dataVerification?.type === "dropdown") {
          dropdownFound = true;
          const options = dataVerification.value1.split(',').map((opt: string) => opt.trim());
          
          let coordinates;
          if (cellInfo.isMerged && cellInfo.mergeRange) {
            const coordArray = [];
            const numRepeats = cellInfo.mergeRange.cs || 1;
            for (let i = 0; i < numRepeats; i++) {
              coordArray.push(`${actualRow},${actualCol + i}`);
            }
            coordinates = coordArray.join(' | ');
            
            // Skip the columns that are part of this merge
            colIndex += (numRepeats - 1);
          } else {
            coordinates = `${actualRow},${actualCol}`;
          }

          return {
            data: options,
            cells: coordinates
          };
        }
      }

      // Show warning only if no dropdown was found
      if (!dropdownFound) {
        return { noDropdown: true };
      }

      return null;

    } catch (error) {
      console.error('Error in processDropdownRow:', error);
      return null;
    }
  };

  const processAdditionalInfoRow = (ref: React.RefObject<WorkbookInstance>, rowIndex: number, selection: any) => {
    const sheet = ref.current?.getSheet();
    if (!sheet || !sheet.data) {
      console.log('No sheet data available');
      return null;
    }

    try {
      const startCol = selection.column[0];
      const endCol = selection.column[1];

      const infoParts = [];
      const coordinates = [];

      for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
        const cellInfo = getMergedCellInfo(ref, rowIndex, colIndex);
        if (!cellInfo) continue;

        const actualRow = cellInfo.isMerged ? cellInfo.mainCellRow : rowIndex;
        const actualCol = cellInfo.isMerged ? cellInfo.mainCellCol : colIndex;

        if (cellInfo.isMerged) {
          const value = cellInfo.value;
          if (value) {
            infoParts.push(value.toString().trim());
            
            const numRepeats = cellInfo.mergeRange?.cs ?? 1;
            for (let i = 0; i < numRepeats; i++) {
              coordinates.push(`${actualRow},${actualCol + i}`);
            }
          }
          
          colIndex += ((cellInfo.mergeRange?.cs ?? 1) - 1) || 0;
        } else {
          if (cellInfo.value) {
            infoParts.push(cellInfo.value.toString().trim());
            coordinates.push(`${actualRow},${actualCol}`);
          }
        }
      }

      if (infoParts.length === 0) {
        return null;
      }

      return {
        data: infoParts.join(' | '),
        cells: coordinates.join(' | ')
      };

    } catch (error) {
      console.error('Error in processAdditionalInfoRow:', error);
      return null;
    }
  };

  const formatQuestions = () => {
    // Group mappings by worksheet and row for validation and formatting
    const questionsByWorksheetAndRow: Record<string, MappedField[]> = {};
    mappedQuestions.forEach(mapping => {
      const row = mapping.excelLocation.cells.split(',')[0];
      const worksheetId = mapping.excelLocation.worksheetId;
      const key = `${worksheetId}:${row}`;
      if (!questionsByWorksheetAndRow[key]) {
        questionsByWorksheetAndRow[key] = [];
      }
      questionsByWorksheetAndRow[key].push(mapping);
    });

    // Format questions according to the interface
    return Object.values(questionsByWorksheetAndRow).map((mappings, index) => {
      const formattedQuestion: FormattedQuestion = {
        questionText: '',
        answerText: null,
        additionalInfo: null,
        dropdownOptions: null,
        excelLocation: {},
        order: index + 1
      };

      mappings.forEach(mapping => {
        const { type } = mapping.excelLocation;
        
        switch (type) {
          case 'question':
            formattedQuestion.questionText = mapping.data.toString();
            formattedQuestion.excelLocation.question = mapping.excelLocation;
            break;
          case 'answer':
            formattedQuestion.answerText = mapping.data.toString();
            formattedQuestion.excelLocation.answer = mapping.excelLocation;
            break;
          case 'dropdown':
            formattedQuestion.dropdownOptions = Array.isArray(mapping.data) 
              ? mapping.data 
              : [mapping.data.toString()];
            formattedQuestion.excelLocation.dropdown = mapping.excelLocation;
            break;
          case 'additional':
            formattedQuestion.additionalInfo = mapping.data.toString();
            formattedQuestion.excelLocation.additional = mapping.excelLocation;
            break;
        }
      });

      return formattedQuestion;
    });
  };

  const handleMapping = (type: 'question' | 'answer' | 'dropdown' | 'additional') => {
    if (!workbookRef?.current) {
      toast.error('Spreadsheet is not ready');
      return;
    }

    const selection = workbookRef.current.getSelection();
    if (!selection || !selection[0]) {
      toast.error('Please select cells first');
      return;
    }

    let bg;
    let stateUpdater;
    let currentState;
    let processor;

    switch (type) {
      case 'question':
        bg = '#FFBFBF';
        stateUpdater = setQuestionData;
        currentState = questionData;
        processor = processQuestionRow;
        break;
      case 'answer':
        bg = '#D3ECE2';
        stateUpdater = setAnswerData;
        currentState = answerData;
        processor = processAnswerRow;
        break;
      case 'dropdown':
        bg = '#FFECDD';
        stateUpdater = setDropdownData;
        currentState = dropdownData;
        processor = processDropdownRow;
        break;
      case 'additional':
        bg = '#E3F0FF';
        stateUpdater = setAdditionalInfoData;
        currentState = additionalInfoData;
        processor = processAdditionalInfoRow;
        break;
      default:
        return;
    }

    const getSelectedCells = (selection: any): Set<string> => {
      const currentWorksheetId = (workbookRef.current?.getSheet()?.order ?? 0).toString();
      const cells = new Set<string>();
      for (let row = selection.row[0]; row <= selection.row[1]; row++) {
        for (let col = selection.column[0]; col <= selection.column[1]; col++) {
          cells.add(`${currentWorksheetId}:${row},${col}`);
        }
      }
      return cells;
    };

    const getMappedCells = (item: MappingData) => {
      return new Set(item.excelLocation.cells.split(" | ").map(cell => 
        `${item.excelLocation.worksheetId}:${cell.trim()}`
      ));
    };

    const selectedCells = getSelectedCells(selection[0]);

    const isOtherTypeMapped = (selectedCells: Set<string>) => {
      const checkOverlap = (dataArray: MappingData[]) => {
        return dataArray.some(item => {
          const mappedCells = getMappedCells(item);
          return Array.from(selectedCells).some(cell => mappedCells.has(cell));
        });
      };

      if (type !== 'question' && checkOverlap(questionData)) return true;
      if (type !== 'answer' && checkOverlap(answerData)) return true;
      if (type !== 'dropdown' && checkOverlap(dropdownData)) return true;
      if (type !== 'additional' && checkOverlap(additionalInfoData)) return true;

      return false;
    };

    if (isOtherTypeMapped(selectedCells)) {
      toast.error('Some cells are already mapped as a different type. Please unmap them first.');
      return;
    }

    const isUnmapping = currentState.some(item => {
      const mappedCells = getMappedCells(item);
      return Array.from(selectedCells).some(cell => mappedCells.has(cell));
    });

    if (isUnmapping) {
      const currentWorksheetId = (workbookRef.current?.getSheet()?.order ?? 0).toString();
      
      stateUpdater(prevData => 
        prevData.filter(item => {
          const mappedCells = getMappedCells(item);
          return !Array.from(selectedCells).some(cell => mappedCells.has(cell));
        })
      );

      // Clear formattedQuestions when unmapping
      setFormattedQuestions([]);
      setIsModalOpen(false);

      // Update mappedQuestions state
      setMappedQuestions(prev => 
        prev.filter(item => {
          const mappedCells = getMappedCells(item);
          return !Array.from(selectedCells).some(cell => mappedCells.has(cell));
        })
      );

      const sheet = workbookRef.current?.getSheet();
      const sheetIndex = sheet?.order || 0;

      interface CellData {
        r: number;
        c: number;
        v?: {
          bg?: string;
        };
      }

      // Create a map of cell coordinates to their original background colors
      const cellBackgrounds: { [key: string]: string } = {};
      if (workbookData && workbookData[sheetIndex]?.celldata) {
        workbookData[sheetIndex].celldata.forEach((cell: CellData) => {
          const key = `${currentWorksheetId}:${cell.r},${cell.c}`;
          if (cell.v?.bg) {
            cellBackgrounds[key] = cell.v.bg;
          }
        });
      }

      Array.from(selectedCells).forEach(cellCoord => {
        const [_, coords] = cellCoord.split(':');
        const [row, col] = coords.split(',').map(Number);
        const bg = cellBackgrounds[cellCoord] || "#FFFFFF";
        workbookRef.current?.setCellFormat(row, col, "bg", bg);
      });
      
      return;
    }

    const newData: MappedField[] = [];
    const processedRows = new Set();

    if (type === 'dropdown') {
      let showWarning = false;
      
      for (let rowIndex = selection[0].row[0]; rowIndex <= selection[0].row[1]; rowIndex++) {
        if (processedRows.has(rowIndex)) continue;

        const processedData = processDropdownRow(workbookRef, rowIndex, selection[0]);
        if (processedData) {
          if (processedData.noDropdown) {
            showWarning = true;
          } else {
            newData.push({
              data: processedData.data,
              excelLocation: {
                type: 'dropdown',
                cells: processedData.cells || '',
                worksheetId: (workbookRef.current?.getSheet()?.order ?? 0).toString()
              }
            });
            processedRows.add(rowIndex);
          }
        }
      }

      if (showWarning) {
        toast.error('No dropdown menu found in selected cells');
        return;
      }
    } else {
      for (let rowIndex = selection[0].row[0]; rowIndex <= selection[0].row[1]; rowIndex++) {
        if (processedRows.has(rowIndex)) continue;

        const processedData = processor(workbookRef, rowIndex, selection[0]);
        if (processedData) {
          newData.push({
            data: processedData.data,
            excelLocation: {
              type: type,
              cells: processedData.cells || '',
              worksheetId: (workbookRef.current?.getSheet()?.order ?? 0).toString()
            }
          });
          processedRows.add(rowIndex);
        }
      }
    }

    if (newData.length > 0) {
      workbookRef.current?.setCellFormatByRange('bg', bg, selection[0]);
      
      // Update all states together
      const updatedMappedQuestions = [...mappedQuestions, ...newData];
      setMappedQuestions(updatedMappedQuestions);
      
      // Convert MappedField to MappingData
      const newMappingData: MappingData[] = newData.map(field => ({
        data: field.data,
        excelLocation: field.excelLocation
      }));
      
      // Update the corresponding state based on type
      switch (type) {
        case 'question':
          setQuestionData(prev => [...prev, ...newMappingData]);
          break;
        case 'answer':
          setAnswerData(prev => [...prev, ...newMappingData]);
          break;
        case 'dropdown':
          setDropdownData(prev => [...prev, ...newMappingData]);
          break;
        case 'additional':
          setAdditionalInfoData(prev => [...prev, ...newMappingData]);
          break;
      }

      // Update formatted questions after all state updates
      setTimeout(() => {
        setFormattedQuestions(formatQuestions());
      }, 0);
      
      
    } else {
      toast.error(`Cannot map empty cells as ${type}`);
    }
  };

  const prepareQuestions = () => {
    if (!questionnaireId) return;
    
    if (questionData.length === 0) {
      toast.error("Please include at least one question before proceeding.");
      return;
    }

    // Check each question has an answer or dropdown
    for (let i = 0; i < questionData.length; i++) {
      const question = questionData[i];
      const questionRows = question.excelLocation.cells.split(" | ").map((cell) => cell.split(",")[0]);
      const questionWorksheetId = question.excelLocation.worksheetId;

      const hasAnswerOrDropdown =
        answerData.some((answer) => {
          if (answer.excelLocation.worksheetId !== questionWorksheetId) return false;
          const answerRow = answer.excelLocation.cells.split(",")[0];
          return questionRows.includes(answerRow);
        }) ||
        dropdownData.some((dropdown) => {
          if (dropdown.excelLocation.worksheetId !== questionWorksheetId) return false;
          const dropdownRow = dropdown.excelLocation.cells.split(",")[0];
          return questionRows.includes(dropdownRow);
        });

      if (!hasAnswerOrDropdown) {
        toast.error(
          "Each question must have at least one answer field OR dropdown field mapped. Do not select whole columns or unrelated cells."
        );
        return;
      }
    }

    setFormattedQuestions(formatQuestions());
    setIsModalOpen(true);
  };

  const handleConfirmMapping = async () => {
    if (!questionnaireId) return;

    try {
      setIsLoading(true);
      await questionService.confirmMapping(questionnaireId, formattedQuestions);
      navigate(`/questionnaire/${questionnaireId}/edit`);
    } catch (error) {
      console.error('Error saving questions:', error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const mappingFields = [
    {
      id: 'question',
      label: 'Question Text',
      description: 'Select cells containing questions',
      bgHover: 'hover:border-red-500 hover:bg-red-50 active:bg-red-100',
      border: 'border-red-200'
    },
    {
      id: 'answer',
      label: 'Text Answer',
      description: 'Select cells for answers',
      bgHover: 'hover:border-green-500 hover:bg-green-50 active:bg-green-100',
      border: 'border-green-200'
    },
    {
      id: 'dropdown',
      label: 'Dropdown Answer',
      description: 'Select cells containing dropdown options',
      bgHover: 'hover:border-yellow-500 hover:bg-yellow-50 active:bg-yellow-100',
      border: 'border-yellow-200'
    }
  ];

  // Add useEffect for logging mapped fields
  useEffect(() => {
    // Group mappings by type
    const questionMappings = questionData;
    const answerMappings = answerData;
    const dropdownMappings = dropdownData;
    const additionalMappings = additionalInfoData;

    console.group(`Current Mapped Fields (Total: ${questionMappings.length + answerMappings.length + dropdownMappings.length + additionalMappings.length})`);
    
    if (questionMappings.length) {
      console.group(`Current mapped questions (${questionMappings.length}):`);
      questionMappings.forEach(m => console.log('Question:', m));
      console.groupEnd();
    }
    
    if (answerMappings.length) {
      console.group(`Current mapped answers (${answerMappings.length}):`);
      answerMappings.forEach(m => console.log('Answer:', m));
      console.groupEnd();
    }
    
    if (dropdownMappings.length) {
      console.group(`Current mapped dropdowns (${dropdownMappings.length}):`);
      dropdownMappings.forEach(m => console.log('Dropdown:', m));
      console.groupEnd();
    }
    
    if (additionalMappings.length) {
      console.group(`Current mapped additional info (${additionalMappings.length}):`);
      additionalMappings.forEach(m => console.log('Additional Info:', m));
      console.groupEnd();
    }

    console.groupEnd();
  }, [questionData, answerData, dropdownData, additionalInfoData]);

  // Also add individual console logs in the handleMapping function
  console.log("questions", questionData);
  console.log("answers", answerData);
  console.log("dropdowns", dropdownData);
  console.log("additionals", additionalInfoData);

  return (
    <div className="bg-white h-screen flex flex-col relative">
      {isLoading && <LoadingOverlay />}
      
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex-shrink-0 p-4 border-b">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">Configure Question Mapping</h1>
              <p className="mt-1 text-sm text-gray-500">
                Select cells or rows in the spreadsheet, then choose a category to assign it
              </p>
            </div>
            <button
              onClick={prepareQuestions}
              className="inline-flex items-center px-6 py-2.5 rounded-lg text-sm font-medium 
                bg-[#1e8259] hover:bg-[#1e8259]/90 text-white shadow-sm hover:shadow-md 
                active:scale-95 transition-all duration-200 min-w-[140px] justify-center"
            >
              Review Mapping
            </button>
          </div>
        </div>

        <div className="flex-shrink-0 space-y-4 p-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="grid grid-cols-4 gap-4">
              {mappingFields.map((field) => (
                <button
                  key={field.id}
                  onClick={() => handleMapping(field.id as 'question' | 'answer' | 'dropdown' | 'additional')}
                  className={`p-4 rounded-lg border ${field.border}
                    ${field.bgHover}
                    transform transition-all duration-150 ease-in-out
                    bg-white text-left focus:outline-none relative`}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">
                        {field.label}
                      </h3>
                      <p className="text-xs text-gray-500 mt-1">{field.description}</p>
                    </div>
                    {(field.id === 'question' || field.id === 'answer' || field.id === 'dropdown' || field.id === 'additional') && (
                      <span className="ml-2 px-2 py-0.5 bg-gray-100 text-gray-600 text-xs font-medium rounded-full">
                        {field.id === 'question' && questionData.length}
                        {field.id === 'answer' && answerData.length}
                        {field.id === 'dropdown' && dropdownData.length}
                        {field.id === 'additional' && additionalInfoData.length}
                      </span>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-hidden w-full max-w-8xl mx-auto px-10">
        {workbookData ? (
          <Workbook 
            ref={workbookRef}
            data={workbookData}
            showToolbar={false}
            showFormulaBar={false}
            cellContextMenu={[]}
            sheetTabContextMenu={[]}
          />
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            Loading workbook...
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999] p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-6xl w-full max-h-[80vh] flex flex-col relative">
            <div className="p-6">
              <h3 className="text-xl font-semibold text-gray-900">Review Mapped Questions</h3>
              <p className="mt-1 text-sm text-gray-500">
                Please review the mapped questions and answers before proceeding
              </p>
            </div>
            
            <div className="flex-1 overflow-auto p-6">
              <div className="space-y-4">
                {formattedQuestions.map((q, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-8">
                      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                        <span className="text-sm text-gray-600 font-medium">{index + 1}</span>
                      </div>
                      <div className="flex-grow space-y-3">
                        <div className="flex items-start gap-2">
                          <span className="text-xs text-gray-500 pt-1 min-w-[80px]">Question:</span>
                          <span className="text-sm text-gray-900">{q.questionText}</span>
                        </div>
                        
                        <div className="space-y-2">
                          {q.excelLocation.answer && (
                            <div className="flex items-start gap-2">
                              <span className="text-xs text-gray-500 pt-1 min-w-[80px]">Text Answer:</span>
                              <span className="text-sm text-gray-900">Mapped</span>
                            </div>
                          )}

                          {q.dropdownOptions && (
                            <div className="flex items-start gap-2">
                              <span className="text-xs text-gray-500 pt-1 min-w-[80px]">Dropdown:</span>
                              <span className="text-sm text-gray-900">
                                {Array.isArray(q.dropdownOptions) 
                                  ? q.dropdownOptions.join(', ') 
                                  : q.dropdownOptions}
                              </span>
                            </div>
                          )}
                          
                          {q.additionalInfo && (
                            <div className="flex items-start gap-2">
                              <span className="text-xs text-gray-500 pt-1 min-w-[80px]">Additional:</span>
                              <span className="text-sm text-gray-900">{q.additionalInfo}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="p-6 flex justify-end gap-3">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-6 py-2.5 rounded-lg text-sm font-medium text-gray-700 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmMapping}
                className="inline-flex items-center px-6 py-2.5 rounded-lg text-sm font-medium text-white bg-[#1e8259]
                  hover:bg-[#1e8259]/90 active:scale-95 transition-all duration-200 min-w-[140px] justify-center"
              >
                <CheckBadgeIcon className="w-4 h-4 mr-2 stroke-[2]" />
                Confirm Mapping
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
