import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import BasteonLogo from '../../assets/basteon-logo.svg';
import { Subscription } from '../Subscription';
import { Settings } from '../Settings';
import {
  CubeTransparentIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/outline';

export const Navigation: React.FC = () => {
  const { user, signOut, getCurrentSession } = useAuth();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false);
  const [trialDaysLeft, setTrialDaysLeft] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkTrialStatus = async () => {
      try {
        const session = await getCurrentSession();
        if (session?.subscription?.status === 'TRIAL') {
          setTrialDaysLeft(session.subscription.trialDaysRemaining || null);
        } else {
          setTrialDaysLeft(null);
        }
      } catch (error) {
        console.error('Error checking trial status:', error);
      }
    };

    checkTrialStatus();
  }, [getCurrentSession]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    try {
      setIsProfileOpen(false);
      await signOut();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Sign out error:', error);
      navigate('/login', { replace: true });
    }
  };

  const handleSubscriptionClick = () => {
    navigate('/subscription');
  };

  const navItems = [
    {
      path: '/questionnaires',
      label: 'Questionnaire',
      icon: <CubeTransparentIcon className="mr-3 h-5 w-5 flex-shrink-0" />
    },
    {
      path: '/knowledge-base',
      label: 'Knowledge Base',
      icon: <CircleStackIcon className="mr-3 h-5 w-5 flex-shrink-0" />
    }
  ];

  return (
    <div className="w-56 bg-white h-screen relative border-r border-gray-100">
      {/* Logo Section */}
      <div className="h-16 flex items-center px-6">
        <img src={BasteonLogo} alt="Basteon Logo" className="h-6" />
      </div>

      {/* Navigation Links */}
      <nav className="px-4 pt-8">
        <div className="space-y-1">
          {navItems.map(({ path, label, icon }) => (
            <NavLink key={path} to={path}>
              {({ isActive }) => (
                <div className={`group flex items-center px-2 py-3 text-sm rounded-lg relative
                  transition-all duration-200 ease-in-out
                  ${isActive
                    ? 'text-[#1e8259] font-medium'
                    : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                  }`}>
                  {icon}
                  <span className="ml-3">{label}</span>
                </div>
              )}
            </NavLink>
          ))}
        </div>
      </nav>

      {/* User Profile Section */}
      <div className="absolute bottom-0 w-56">
        {/* Trial Days Left */}
        {trialDaysLeft !== null && (
          <div className="px-3 mb-2">
            <div className={`flex items-center justify-between px-3 py-2.5 bg-gradient-to-r from-gray-50 to-white border rounded-lg
              ${trialDaysLeft <= 5 ? 'border-red-200' : 'border-gray-100'}`}>
              <div className="flex-1">
                <p className={`text-xs ${trialDaysLeft <= 5 ? 'text-red-600' : 'text-gray-600'}`}>
                  <span className="font-medium">{Math.max(0, trialDaysLeft)}</span> {trialDaysLeft === 1 ? 'day' : 'days'} left in trial
                </p>
              </div>
              <button 
                onClick={handleSubscriptionClick}
                className={`text-xs font-medium ${trialDaysLeft <= 5 ? 'text-red-600 hover:text-red-700' : 'text-[#1e8259] hover:text-[#156544]'}`}
              >
                Upgrade
              </button>
            </div>
          </div>
        )}

        <div className="px-2 py-4 relative" ref={dropdownRef}>
          <div 
            className="flex items-center cursor-pointer hover:bg-gray-50 p-1 rounded-lg"
            onClick={() => setIsProfileOpen(!isProfileOpen)}
          >
            <div className="flex-shrink-0">
              <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                <span className="text-sm font-medium text-gray-600">
                  {user?.givenName?.[0]}{user?.familyName?.[0]}
                </span>
              </div>
            </div>
            <div className="ml-3 flex-grow overflow-hidden">
              <p className="text-sm font-medium text-gray-700 truncate">
                {user?.givenName} {user?.familyName}
              </p>
              <p className="text-xs text-gray-500 truncate">{user?.email}</p>
            </div>
          </div>

          {/* Profile Dropdown */}
          {isProfileOpen && (
            <div className="absolute bottom-full left-0 w-64 mb-2 bg-white rounded-lg shadow-lg border border-gray-200">
              <div className="py-1">
                <button
                  onClick={() => {
                    setIsSettingsOpen(true);
                    setIsProfileOpen(false);
                  }}
                  className="w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
                >
                  <Cog6ToothIcon className="mr-3 h-5 w-5 text-gray-400" />
                  Settings
                </button>

                <button
                  onClick={handleSubscriptionClick}
                  className="w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
                >
                  <CreditCardIcon className="mr-3 h-5 w-5 text-gray-400" />
                  Subscription & Billing
                </button>

                <div className="border-t border-gray-100 my-1"></div>

                <button
                  onClick={handleSignOut}
                  className="w-full text-left px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 flex items-center"
                >
                  <ArrowLeftStartOnRectangleIcon className="mr-3 h-5 w-5 text-gray-400" />
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Settings Modal */}
      {isSettingsOpen && (
        <Settings isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
      )}

      {/* Subscription Modal */}
      {isSubscriptionOpen && (
        <Subscription 
          isOpen={true} 
          onClose={() => setIsSubscriptionOpen(false)} 
        />
      )}
    </div>
  );
}; 