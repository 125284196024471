import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { stripeService } from '../services/stripeService';
import { toast } from 'react-hot-toast';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

// Cache duration in milliseconds (5 minutes)
const CACHE_DURATION = 5 * 60 * 1000;

interface SubscriptionProps {
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onClose?: () => void;
}

interface SubscriptionStatus {
  status: string;
  planId?: string;
  endsAt?: string;
  billingCycle?: string;
  maxQuestionnaires: number;
  maxTeamMembers: number;
  maxKnowledgeBases: number;
  currentQuestionnaires: number;
  currentTeamMembers: number;
  currentKnowledgeBases: number;
  subscriptionEndsAt?: string;
  trialEndsAt?: string;
}

interface CachedStatus {
  data: SubscriptionStatus;
  timestamp: number;
}

// In-memory cache
let subscriptionCache: CachedStatus | null = null;

const BASE_PRICE = 450;
const QUESTIONNAIRE_PACK_PRICE = 345;
const QUESTIONNAIRES_PER_PACK = 5;
const TEAM_MEMBER_PRICE = 59;
const BASE_TEAM_MEMBERS = 2;

export function Subscription({ isOpen = true, setIsOpen, onClose }: SubscriptionProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpgradeLoading, setIsUpgradeLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(null);
  const [usageMetrics, setUsageMetrics] = useState<{ currentQuestionnaires: number; maxQuestionnaires: number; currentTeamMembers: number; maxTeamMembers: number } | null>(null);
  const [additionalQuestionnairePacks, setAdditionalQuestionnairePacks] = useState(0);
  const [additionalTeamMembers, setAdditionalTeamMembers] = useState(0);
  const [showUpgradeControls, setShowUpgradeControls] = useState(false);
  const [pendingChanges, setPendingChanges] = useState<{
    questionnairePacks?: number;
    teamMembers?: number;
  } | null>(null);
  const [displayMetrics, setDisplayMetrics] = useState<{
    currentQuestionnaires: number;
    maxQuestionnaires: number;
    currentTeamMembers: number;
    maxTeamMembers: number;
  } | null>(null);

  // Calculate total price
  const calculateTotal = () => {
    return BASE_PRICE + 
           (additionalQuestionnairePacks * QUESTIONNAIRE_PACK_PRICE) +
           (additionalTeamMembers * TEAM_MEMBER_PRICE);
  };

  // Calculate total questionnaires
  const calculateTotalQuestionnaires = () => {
    return 10 + (additionalQuestionnairePacks * QUESTIONNAIRES_PER_PACK);
  };

  // Calculate total team members
  const calculateTotalTeamMembers = () => {
    return BASE_TEAM_MEMBERS + additionalTeamMembers;
  };

  // Handle add/remove questionnaire packs
  const handleDecreasePacks = () => {
    if (additionalQuestionnairePacks > 0) {
      setAdditionalQuestionnairePacks(prev => prev - 1);
    }
  };

  const handleIncreasePacks = () => {
    setAdditionalQuestionnairePacks(prev => prev + 1);
  };

  // Handle add/remove team members
  const handleDecreaseTeamMembers = () => {
    if (additionalTeamMembers > 0) {
      setAdditionalTeamMembers(prev => prev - 1);
    }
  };

  const handleIncreaseTeamMembers = () => {
    setAdditionalTeamMembers(prev => prev + 1);
  };

  const isCacheValid = () => {
    return subscriptionCache && 
           (Date.now() - subscriptionCache.timestamp) < CACHE_DURATION;
  };

  const fetchSubscriptionStatus = async (force = false) => {
    try {
      // Check cache first unless force refresh is requested
      if (!force && isCacheValid()) {
        setSubscriptionStatus(subscriptionCache!.data);
        setUsageMetrics({
          currentQuestionnaires: subscriptionCache!.data.currentQuestionnaires,
          maxQuestionnaires: subscriptionCache!.data.maxQuestionnaires,
          currentTeamMembers: subscriptionCache!.data.currentTeamMembers,
          maxTeamMembers: subscriptionCache!.data.maxTeamMembers
        });
        setDisplayMetrics({
          currentQuestionnaires: subscriptionCache!.data.currentQuestionnaires,
          maxQuestionnaires: subscriptionCache!.data.maxQuestionnaires,
          currentTeamMembers: subscriptionCache!.data.currentTeamMembers,
          maxTeamMembers: subscriptionCache!.data.maxTeamMembers
        });
        setIsInitializing(false);
        return;
      }

      const status = await stripeService.getSubscriptionStatus();
      
      // Update cache
      subscriptionCache = {
        data: status,
        timestamp: Date.now()
      };
      
      setSubscriptionStatus(status);
      const newMetrics = {
        currentQuestionnaires: status.currentQuestionnaires,
        maxQuestionnaires: status.maxQuestionnaires,
        currentTeamMembers: status.currentTeamMembers,
        maxTeamMembers: status.maxTeamMembers
      };
      setUsageMetrics(newMetrics);
      setDisplayMetrics(newMetrics);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setMessage({ 
        type: 'error', 
        text: 'Failed to load subscription status. Please try again.' 
      });
      // If cache exists, use it as fallback
      if (subscriptionCache) {
        setSubscriptionStatus(subscriptionCache.data);
        const fallbackMetrics = {
          currentQuestionnaires: subscriptionCache.data.currentQuestionnaires,
          maxQuestionnaires: subscriptionCache.data.maxQuestionnaires,
          currentTeamMembers: subscriptionCache.data.currentTeamMembers,
          maxTeamMembers: subscriptionCache.data.maxTeamMembers
        };
        setUsageMetrics(fallbackMetrics);
        setDisplayMetrics(fallbackMetrics);
      }
    } finally {
      setIsInitializing(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Always force a fresh fetch when component is opened
      fetchSubscriptionStatus(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    
    if (success === 'true') {
      // Clear the cache to force a fresh fetch
      subscriptionCache = null;
      
      // Force refresh subscription status
      fetchSubscriptionStatus(true).then(() => {
        setMessage({ type: 'success', text: 'Your subscription has been updated successfully!' });
        // Clear the URL parameters
        navigate('/subscription', { replace: true });
      });
    } else if (success === 'false') {
      setMessage({ type: 'error', text: 'The payment was cancelled. Please try again.' });
      navigate('/subscription', { replace: true });
    }
  }, [location.search, navigate]);

  const handlePortalAccess = async () => {
    try {
      setIsLoading(true);
      const { url } = await stripeService.createPortalSession();
      window.location.href = url;
    } catch (error) {
      console.error('Error accessing customer portal:', error);
      setMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to access customer portal. Please try again.' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (setIsOpen) {
      setIsOpen(false);
    } else {
      navigate('/');
    }
  };

  const updateQuantities = async ({ 
    additionalQuestionnairePacks, 
    additionalTeamMembers 
  }: { 
    additionalQuestionnairePacks?: number;
    additionalTeamMembers?: number;
  }) => {
    try {
      setIsUpgradeLoading(true);
      await stripeService.updateQuantities({
        additionalQuestionnairePacks,
        additionalTeamMembers
      });

      // Clear cache
      subscriptionCache = null;
      
      // Add a small delay to ensure webhook processing is complete
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Get fresh data
      const status = await stripeService.getSubscriptionStatus();
      
      // Update all states with new data
      const newMetrics = {
        currentQuestionnaires: status.currentQuestionnaires,
        maxQuestionnaires: status.maxQuestionnaires,
        currentTeamMembers: status.currentTeamMembers,
        maxTeamMembers: status.maxTeamMembers
      };
      
      // Update all states synchronously
      setDisplayMetrics(newMetrics);
      setUsageMetrics(newMetrics);
      setSubscriptionStatus(status);
      
      toast.success('Successfully updated subscription');
      setShowUpgradeControls(false);
      setPendingChanges(null);
    } catch (error) {
      console.error('Error updating quantities:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to update subscription');
    } finally {
      setIsUpgradeLoading(false);
    }
  };

  if (!isOpen) return null;

  const isSubscribed = subscriptionStatus?.status === 'active' || subscriptionStatus?.status === 'active_canceling';
  const isCanceling = subscriptionStatus?.status === 'active_canceling';

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-xl mx-auto px-4 py-12">
        {isInitializing ? (
          <div className="animate-pulse">
            {/* Loading Header */}
            <div className="text-center mb-12">
              <div className="h-9 w-48 bg-gray-200 rounded mx-auto" />
            </div>

            {/* Loading Plan Card */}
            <div className="border border-gray-200 rounded-2xl p-8 shadow-sm space-y-4">
              {/* Title */}
              <div className="h-6 w-24 bg-gray-200 rounded" />
              
              {/* Price */}
              <div className="flex items-baseline gap-1">
                <div className="h-8 w-16 bg-gray-200 rounded" />
                <div className="h-4 w-16 bg-gray-200 rounded" />
              </div>
              
              {/* Description */}
              <div className="h-4 w-full bg-gray-200 rounded" />
              
              {/* Button */}
              <div className="h-14 w-full bg-gray-200 rounded-md my-6" />
              
              {/* Features List */}
              <div className="space-y-4 pt-2">
                {[1, 2, 3, 4, 5].map((i) => (
                  <div key={i} className="flex items-center gap-3">
                    <div className="h-5 w-5 bg-gray-200 rounded-full flex-shrink-0" />
                    <div className="h-4 w-full bg-gray-200 rounded" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Header */}
            <div className="text-center mb-12">
              <h1 className="text-3xl font-medium text-gray-800">
                {isSubscribed ? 'Your Subscription' : 'Upgrade your plan'}
              </h1>
              {isCanceling && (
                <div className="mt-4 p-4 rounded-md bg-yellow-50 text-yellow-800">
                  Your subscription will be canceled at the end of the current billing period
                  {subscriptionStatus?.endsAt && ` (${new Date(subscriptionStatus.endsAt).toLocaleDateString()})`}
                </div>
              )}
              {message && (
                <div className={`mt-4 p-4 rounded-md ${
                  message.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                }`}>
                  {message.text}
                </div>
              )}
            </div>

            {/* Plan Card */}
            <div className="border border-gray-200 rounded-2xl p-8">
              <h2 className="text-xl font-medium text-gray-800">Basteon Standard Plan</h2>
              {!isSubscribed && (
              <div className="mt-4 mb-6">
                  <span className="text-3xl font-medium">€</span>
                  <span className="text-4xl font-medium">{calculateTotal()}</span>
                  <span className="text-gray-600 text-base ml-1">/month</span>
                </div>
              )}
              <div className={`text-gray-600 mb-6 ${isSubscribed ? 'mt-6' : ''}`}>
                {!isSubscribed && <p className="font-medium mb-2">Your Plan Includes:</p>}
                <ul className="space-y-1 text-sm">
                  <li className="flex items-center">
                    <svg className="h-4 w-4 text-[#1e8259] mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    {isSubscribed ? (
                      pendingChanges?.questionnairePacks !== undefined ? 
                        `${10 + (pendingChanges.questionnairePacks * 5)} AI-powered questionnaires monthly` :
                        `${usageMetrics?.maxQuestionnaires || 10} AI-powered questionnaires monthly`
                    ) : (
                      `${calculateTotalQuestionnaires()} AI-powered questionnaires monthly`
                    )}
                  </li>
                  <li className="flex items-center">
                    <svg className="h-4 w-4 text-[#1e8259] mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    {isSubscribed ? (
                      pendingChanges?.teamMembers !== undefined ? 
                        `${2 + pendingChanges.teamMembers} team members` :
                        `${usageMetrics?.maxTeamMembers || 2} team members`
                    ) : (
                      `${calculateTotalTeamMembers()} team members`
                    )}
                  </li>
                  <li className="flex items-center">
                    <svg className="h-4 w-4 text-[#1e8259] mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Unlimited AI knowledge base items
                  </li>
                  <li className="flex items-center">
                    <svg className="h-4 w-4 text-[#1e8259] mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Dedicated customer support
                  </li>
                  <li className="flex items-center">
                    <svg className="h-4 w-4 text-[#1e8259] mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Multi-Factor Authentication
                  </li>
                </ul>
              </div>

              {isSubscribed ? (
                <>
                  {/* Usage Statistics */}
                  <div className="mb-6 space-y-4">
                    <div>
                      <div className="flex justify-between text-sm text-gray-600 mb-1">
                        <span>Questionnaires</span>
                        <span>{displayMetrics?.currentQuestionnaires || 0} / {displayMetrics?.maxQuestionnaires || 10}</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className="bg-[#1e8259] h-2 rounded-full" 
                          style={{ 
                            width: `${((displayMetrics?.currentQuestionnaires || 0) / (displayMetrics?.maxQuestionnaires || 1)) * 100}%` 
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between text-sm text-gray-600 mb-1">
                        <span>Team Members</span>
                        <span>{displayMetrics?.currentTeamMembers || 0} / {displayMetrics?.maxTeamMembers || 2}</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className="bg-[#1e8259] h-2 rounded-full" 
                          style={{ 
                            width: `${((displayMetrics?.currentTeamMembers || 0) / (displayMetrics?.maxTeamMembers || 1)) * 100}%` 
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Single Subscription Management Section */}
                  <div className="space-y-4">
                    <button 
                      onClick={handlePortalAccess}
                      disabled={isLoading}
                      className="w-full border border-[#1e8259] text-[#1e8259] py-3.5 px-4 rounded-md hover:bg-[#1e8259]/5 transition-all duration-200 font-medium shadow-sm hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoading ? 'Loading...' : 'Manage Subscription'}
                    </button>

                    <button
                      onClick={() => setShowUpgradeControls(!showUpgradeControls)}
                      className="w-full text-gray-600 hover:text-gray-800 py-2 text-sm font-medium flex items-center justify-center gap-1"
                    >
                      {showUpgradeControls ? 'Hide upgrade options' : 'Want to upgrade your plan?'}
                      <svg 
                        className={`w-4 h-4 transition-transform ${showUpgradeControls ? 'rotate-180' : ''}`}
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                  </div>

                  {/* Collapsible Upgrade Controls */}
                  {showUpgradeControls && (
                    <div className="mt-6 space-y-6">
                      {/* Plan Components */}
                      <div className="space-y-6">
                        {/* Questionnaires Component */}
                        <div className="bg-gray-50 rounded-lg p-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <h4 className="font-medium text-gray-800">Additional Questionnaire Packs</h4>
                              <p className="text-sm text-gray-600">Add packs of 5 additional questionnaires</p>
                            </div>
                            <div className="flex items-center space-x-3 ml-4 self-center">
                              <button 
                                className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={() => {
                                  const currentPacks = pendingChanges?.questionnairePacks ?? 
                                    Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5);
                                  if (currentPacks > Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)) {
                                    setPendingChanges(prev => ({
                                      ...prev,
                                      questionnairePacks: currentPacks - 1
                                    }));
                                  }
                                }}
                                disabled={isLoading || (pendingChanges?.questionnairePacks ?? Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)) <= Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)}
                              >
                                <MinusIcon className="h-4 w-4 text-gray-600" />
                              </button>
                              <span className="text-gray-800 font-medium w-4 text-center">
                                {(pendingChanges?.questionnairePacks ?? Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)) - Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)}
                              </span>
                              <button 
                                className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100"
                                onClick={() => {
                                  const currentPacks = pendingChanges?.questionnairePacks ?? 
                                    Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5);
                                  setPendingChanges(prev => ({
                                    ...prev,
                                    questionnairePacks: currentPacks + 1
                                  }));
                                }}
                                disabled={isLoading}
                              >
                                <PlusIcon className="h-4 w-4 text-gray-600" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Team Members Component */}
                        <div className="bg-gray-50 rounded-lg p-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <h4 className="font-medium text-gray-800">Additional Team Members</h4>
                              <p className="text-sm text-gray-600">Add more team members to your plan</p>
                            </div>
                            <div className="flex items-center space-x-3 ml-4 self-center">
                              <button 
                                className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={() => {
                                  const currentMembers = pendingChanges?.teamMembers ?? 
                                    ((usageMetrics?.maxTeamMembers || 2) - 2);
                                  if (currentMembers > ((usageMetrics?.maxTeamMembers || 2) - 2)) {
                                    setPendingChanges(prev => ({
                                      ...prev,
                                      teamMembers: currentMembers - 1
                                    }));
                                  }
                                }}
                                disabled={isLoading || (pendingChanges?.teamMembers ?? ((usageMetrics?.maxTeamMembers || 2) - 2)) <= ((usageMetrics?.maxTeamMembers || 2) - 2)}
                              >
                                <MinusIcon className="h-4 w-4 text-gray-600" />
                              </button>
                              <span className="text-gray-800 font-medium w-4 text-center">
                                {(pendingChanges?.teamMembers ?? ((usageMetrics?.maxTeamMembers || 2) - 2)) - ((usageMetrics?.maxTeamMembers || 2) - 2)}
                              </span>
                              <button 
                                className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100"
                                onClick={() => {
                                  const currentMembers = pendingChanges?.teamMembers ?? 
                                    ((usageMetrics?.maxTeamMembers || 2) - 2);
                                  setPendingChanges(prev => ({
                                    ...prev,
                                    teamMembers: currentMembers + 1
                                  }));
                                }}
                                disabled={isLoading}
                              >
                                <PlusIcon className="h-4 w-4 text-gray-600" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Total Section */}
                      {pendingChanges && (
                        <div className="mt-6 pt-6 border-t border-gray-200">
                          <div className="space-y-2">
                            {pendingChanges.questionnairePacks !== undefined && 
                             pendingChanges.questionnairePacks > Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5) && (
                              <div className="flex justify-between items-center text-sm text-gray-600">
                                <span>Additional Questionnaires ({(pendingChanges.questionnairePacks - Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)) * 5})</span>
                                <span>+ €{(pendingChanges.questionnairePacks - Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)) * 345}</span>
                              </div>
                            )}
                            {pendingChanges.teamMembers !== undefined && 
                             pendingChanges.teamMembers > ((usageMetrics?.maxTeamMembers || 2) - 2) && (
                              <div className="flex justify-between items-center text-sm text-gray-600">
                                <span>Additional Team Members ({pendingChanges.teamMembers - ((usageMetrics?.maxTeamMembers || 2) - 2)})</span>
                                <span>+ €{(pendingChanges.teamMembers - ((usageMetrics?.maxTeamMembers || 2) - 2)) * 59}</span>
                              </div>
                            )}
                            <div className="flex justify-between items-center text-lg font-medium text-gray-800 pt-2">
                              <span>Additional Monthly Cost</span>
                              <span>€{
                                (pendingChanges.questionnairePacks !== undefined ? 
                                  (pendingChanges.questionnairePacks - Math.floor(((usageMetrics?.maxQuestionnaires || 10) - 10) / 5)) * 345 : 0) + 
                                (pendingChanges.teamMembers !== undefined ? 
                                  (pendingChanges.teamMembers - ((usageMetrics?.maxTeamMembers || 2) - 2)) * 59 : 0)
                              }</span>
                            </div>
                            <div className="text-xs text-gray-500 text-right">
                              Price excluding VAT
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Confirmation Button */}
                      {pendingChanges && (
                        <div className="pt-4">
                          <button
                            onClick={() => {
                              updateQuantities({
                                additionalQuestionnairePacks: pendingChanges.questionnairePacks,
                                additionalTeamMembers: pendingChanges.teamMembers
                              });
                            }}
                            className="w-full py-2 px-4 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors duration-200 font-medium"
                            disabled={isUpgradeLoading}
                          >
                            {isUpgradeLoading ? 'Upgrading...' : 'Upgrade Subscription'}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
              {/* Plan Components */}
              <div className="space-y-6">
                {/* Questionnaires Component */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="font-medium text-gray-800">Additional Questionnaire Packs</h4>
                      <p className="text-sm text-gray-600">Add packs of 5 additional questionnaires</p>
                    </div>
                    <div className="flex items-center space-x-3 ml-4 self-center">
                      <button 
                        className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={handleDecreasePacks}
                        disabled={additionalQuestionnairePacks === 0}
                      >
                        <svg className="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                        </svg>
                      </button>
                      <span className="text-gray-800 font-medium w-4 text-center">{additionalQuestionnairePacks}</span>
                      <button 
                        className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100"
                        onClick={handleIncreasePacks}
                      >
                        <svg className="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                {/* Team Members Component */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="font-medium text-gray-800">Additional Team Members</h4>
                      <p className="text-sm text-gray-600">Add more team members to your plan</p>
                    </div>
                    <div className="flex items-center space-x-3 ml-4 self-center">
                      <button 
                        className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={handleDecreaseTeamMembers}
                        disabled={additionalTeamMembers === 0}
                      >
                        <svg className="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                        </svg>
                      </button>
                      <span className="text-gray-800 font-medium w-4 text-center">{additionalTeamMembers}</span>
                      <button 
                        className="h-8 w-8 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100"
                        onClick={handleIncreaseTeamMembers}
                      >
                        <svg className="h-4 w-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Total Section */}
              <div className="mt-6 space-y-2">
                <div className="space-y-2">
                  <div className="flex justify-between items-center text-sm text-gray-600">
                    <span>Standard Base Plan</span>
                    <span>€{BASE_PRICE}</span>
                  </div>
                  {additionalQuestionnairePacks > 0 && (
                    <div className="flex justify-between items-center text-sm text-gray-600">
                      <span>Additional Questionnaires ({additionalQuestionnairePacks * QUESTIONNAIRES_PER_PACK})</span>
                      <span>+ {additionalQuestionnairePacks * QUESTIONNAIRE_PACK_PRICE}</span>
                    </div>
                  )}
                  {additionalTeamMembers > 0 && (
                    <div className="flex justify-between items-center text-sm text-gray-600">
                      <span>Additional Team Members ({additionalTeamMembers})</span>
                      <span>+ {additionalTeamMembers * TEAM_MEMBER_PRICE}</span>
                    </div>
                  )}
                  <div className="flex justify-between items-center text-lg font-medium text-gray-800 pt-2">
                    <span>Total Monthly Price</span>
                    <span>€{calculateTotal()}</span>
                  </div>
                  <div className="text-xs text-gray-500 text-right">
                    Price excluding VAT
                  </div>
                </div>
              </div>

              {/* Subscription Management Buttons */}
              <div className="mt-6">
                {isSubscribed ? (
                  <button
                    onClick={handlePortalAccess}
                    disabled={isLoading}
                    className="w-full border border-[#1e8259] text-[#1e8259] py-3.5 px-4 rounded-md hover:bg-[#1e8259]/5 transition-all duration-200 font-medium shadow-sm hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isLoading ? 'Loading...' : 'Manage Subscription'}
                  </button>
                ) : (
                  <button
                    onClick={async () => {
                      try {
                        setIsLoading(true);
                        const { url } = await stripeService.createCheckoutSession({
                          additionalQuestionnairePacks,
                          additionalTeamMembers
                        });
                        window.location.href = url;
                      } catch (error) {
                        console.error('Error creating payment link:', error);
                        toast.error('Failed to start subscription. Please try again.');
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                    disabled={isLoading}
                    className="w-full bg-[#1e8259] text-white py-3.5 px-4 rounded-md hover:bg-[#1e8259]/90 transition-all duration-200 font-medium shadow-sm hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isLoading ? 'Loading...' : 'Start Subscription'}
                  </button>
                )}
              </div>
                </>
              )}
            </div>

            {/* Footer */}
            <div className="text-center mt-8">
              <div className="inline-flex items-center justify-center text-base text-gray-600">
                <svg className="h-5 w-5 text-gray-500 mr-2" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6 6 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>
                Need more capabilities for your business?
              </div>
              <div className="mt-1">
                <a 
                  href="mailto:support@basteon.com?subject=Enterprise%20Plans"
                  className="text-[#1e8259] hover:text-[#1e8259]/80 font-medium text-base inline-block"
                >
                  Contact us
                </a>
              </div>
            </div>
          </>
        )}

        {/* Close button - always visible */}
        <div className="absolute top-4 right-4">
          <button 
            onClick={handleClose}
            className="text-gray-400 hover:text-gray-500 p-2"
          >
            <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}