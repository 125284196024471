import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import teamService, { TeamMember } from '../services/teamService';
import { QRCodeSVG } from 'qrcode.react';
import {
  UserIcon,
  LockClosedIcon,
  UsersIcon,
  TrashIcon,
  Cog6ToothIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { TeamMembersResponse } from '../types/team';

type SettingsTab = 'profile' | 'security' | 'team';

interface SettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Settings: React.FC<SettingsProps> = ({ isOpen, onClose }) => {
  const { user, updateUserAttributes, changePassword, setupMFA, verifyAndEnableMFA, disableMFA, getMFAStatus } = useAuth();
  const [activeTab, setActiveTab] = useState<SettingsTab>('profile');
  const modalRef = useRef<HTMLDivElement>(null);
  const [teamMembers, setTeamMembers] = useState<TeamMembersResponse | null>(null);
  const [isInviting, setIsInviting] = useState(false);
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const [mfaSetupData, setMFASetupData] = useState<{ secretCode: string; qrCodeUrl: string } | null>(null);
  const [mfaCode, setMFACode] = useState('');
  const [isLoadingMFA, setIsLoadingMFA] = useState(false);
  const [disableCode, setDisableCode] = useState('');
  const [formData, setFormData] = useState({
    firstName: user?.givenName || '',
    lastName: user?.familyName || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDisableForm, setShowDisableForm] = useState(false);

  // Load data silently when modal opens
  useEffect(() => {
    if (isOpen) {
      // Load MFA status
      getMFAStatus().then(status => {
        setIsMFAEnabled(status.enabled);
      }).catch(error => {
        console.error('Error loading MFA status:', error);
      });

      // Load team members
      fetchTeamMembers().catch(error => {
        console.error('Error loading team members:', error);
      });
    }
  }, [isOpen]);

  // Remove the individual tab-based loading effects
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  // Helper function to check if user is admin
  const isAdmin = () => {
    return user?.role?.toLowerCase() === 'admin';
  };

  // Re-use all the existing handlers and functions from Navigation.tsx
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleProfileSubmit = async () => {
    try {
      setIsSubmitting(true);
      
      if (formData.firstName !== user?.givenName || formData.lastName !== user?.familyName) {
        await updateUserAttributes({
          givenName: formData.firstName || '',
          familyName: formData.lastName || ''
        });
        toast.success('Profile updated successfully!');
      }
    } catch (err: any) {
      toast.error(err.message || 'Failed to update profile');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePasswordSubmit = async () => {
    if (!formData.currentPassword || !formData.newPassword || !formData.confirmPassword) {
      toast.error('Please fill in all password fields');
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      toast.error('New passwords do not match');
      return;
    }

    setIsSubmitting(true);
    try {
      await changePassword(formData.currentPassword, formData.newPassword);
      toast.success('Password updated successfully');
      setFormData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
    } catch (error: any) {
      console.error('Failed to change password:', error);
      toast.error(error.message || 'Failed to change password');
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await teamService.getTeamMembers();
      setTeamMembers(response);
    } catch (error) {
      console.error('Failed to fetch team members:', error);
      toast.error('Failed to fetch team members');
    }
  };

  const handleRoleChange = async (userId: string, newRole: string) => {
    if (!isAdmin()) {
      toast.error('Only admins can change user roles');
      return;
    }

    try {
      await teamService.updateMemberRole(userId, newRole);
      await fetchTeamMembers();
      toast.success('Role updated successfully');
    } catch (error: any) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Failed to update role');
      }
      console.error('Failed to update role:', error);
    }
  };

  const handleRemoveMember = async (userId: string) => {
    if (!isAdmin()) {
      toast.error('Only admins can remove team members');
      return;
    }
    try {
      await teamService.removeMember(userId);
      await fetchTeamMembers();
      toast.success('Team member removed successfully');
    } catch (error) {
      console.error('Failed to remove member:', error);
      toast.error('Failed to remove team member');
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      if (currentEmail && isValidEmail(currentEmail)) {
        setEmailList([...emailList, currentEmail]);
        setCurrentEmail('');
      } else if (currentEmail) {
        toast.error('Please enter a valid email address');
      }
    }
  };

  const handleInviteSubmit = async () => {
    const emailsToInvite = [...emailList];
    if (currentEmail) {
      if (!isValidEmail(currentEmail)) {
        toast.error('Please enter a valid email address');
        return;
      }
      emailsToInvite.push(currentEmail);
    }
    
    if (emailsToInvite.length === 0) return;
    
    setIsInviting(true);
    let success = true;

    try {
      for (const email of emailsToInvite) {
        try {
          const result = await teamService.inviteMember(email, 'Member');
          if (!result) {
            success = false;
            break;
          }
        } catch (error: any) {
          success = false;
          console.error('Error inviting member:', error);
          toast.error(error.message || 'Failed to invite team member');
          break;
        }
      }

      if (success) {
        await fetchTeamMembers();
        setEmailList([]);
        setCurrentEmail('');
        toast.success('Team members invited successfully');
      }
    } catch (error: any) {
      success = false;
      console.error('Failed to invite members:', error);
      toast.error(error.message || 'Failed to invite team members');
    } finally {
      setIsInviting(false);
    }
  };

  const handleSetupMFA = async () => {
    setIsLoadingMFA(true);
    try {
      const setupData = await setupMFA();
      setMFASetupData(setupData);
    } catch (error) {
      console.error('Failed to setup MFA:', error);
      toast.error('Failed to setup MFA');
    } finally {
      setIsLoadingMFA(false);
    }
  };

  const handleVerifyAndEnableMFA = async () => {
    if (!mfaCode) {
      toast.error('Please enter the verification code');
      return;
    }

    setIsLoadingMFA(true);
    try {
      await verifyAndEnableMFA(mfaCode);
      setIsMFAEnabled(true);
      setMFASetupData(null);
      setMFACode('');
      toast.success('MFA enabled successfully');
    } catch (error) {
      console.error('Failed to verify MFA:', error);
      toast.error('Failed to verify MFA code');
    } finally {
      setIsLoadingMFA(false);
    }
  };

  const handleDisableMFA = async () => {
    if (!disableCode) {
      toast.error('Please enter your verification code');
      return;
    }

    if (disableCode.length !== 6 || !/^\d+$/.test(disableCode)) {
      toast.error('Please enter a valid 6-digit code');
      setDisableCode('');
      return;
    }

    try {
      setIsLoadingMFA(true);
      await disableMFA(disableCode);
      
      // Verify MFA was actually disabled by checking the status
      const status = await getMFAStatus();
      if (!status.enabled) {
        setIsMFAEnabled(false);
        setMFACode('');
        setDisableCode('');
        toast.success('2FA has been disabled successfully');
      } else {
        throw new Error('Failed to disable 2FA. Please try again.');
      }
    } catch (error: any) {
      console.error('Error disabling MFA:', error);
      // Show the exact error message from the API
      const errorMessage = error.response?.data?.error || error.message || 'Failed to disable 2FA';
      toast.error(errorMessage);
      setDisableCode('');
    } finally {
      setIsLoadingMFA(false);
    }
  };

  const removeEmail = (indexToRemove: number) => {
    setEmailList(emailList.filter((_, index) => index !== indexToRemove));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/25 backdrop-blur-sm z-[9999] flex items-center justify-center overflow-hidden">
      <div ref={modalRef} className="bg-white rounded-xl shadow-xl w-full max-w-4xl mx-4 my-8 max-h-[90vh] overflow-y-auto relative">
        {/* Header */}
        <div className="px-8 py-4 flex items-center justify-between sticky top-0 bg-white z-[9999]">
          <div className="flex items-center gap-2">
            <Cog6ToothIcon className="w-5 h-5 text-gray-900" />
            <h3 className="text-lg font-medium text-gray-900">Settings</h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 p-1 rounded-lg hover:bg-gray-50 transition-colors"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="flex pb-16">
          {/* Tabs */}
          <div className="w-56">
            <nav className="space-y-1 p-4">
              <button
                onClick={() => setActiveTab('profile')}
                className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-lg ${
                  activeTab === 'profile'
                    ? 'text-[#1e8259] font-medium'
                    : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                }`}
              >
                <UserIcon className="w-5 h-5 mr-3" />
                Profile
              </button>
              <button
                onClick={() => setActiveTab('security')}
                className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-lg ${
                  activeTab === 'security'
                    ? 'text-[#1e8259] font-medium'
                    : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                }`}
              >
                <LockClosedIcon className="w-5 h-5 mr-3" />
                Security
              </button>
              <button
                onClick={() => setActiveTab('team')}
                className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-lg ${
                  activeTab === 'team'
                    ? 'text-[#1e8259] font-medium'
                    : 'text-gray-500 font-normal hover:bg-gray-50 hover:text-gray-900'
                }`}
              >
                <UsersIcon className="w-5 h-5 mr-3" />
                Team Members
              </button>
            </nav>
          </div>

          {/* Content */}
          <div className="flex-1 px-8 py-6 min-h-[320px] w-[500px]">
            {activeTab === 'profile' && (
              <div className="space-y-4">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">Profile</h3>
                  <button
                    onClick={handleProfileSubmit}
                    disabled={isSubmitting}
                    className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 rounded-lg transition-all disabled:text-gray-400 disabled:border-gray-100 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Saving...' : 'Update Profile'}
                  </button>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter your first name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Enter your last name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                  <p className="text-xs text-gray-500 mb-2">Your email address is used for signing in and notifications.</p>
                  <input
                    type="email"
                    placeholder="your@email.com"
                    value={user?.email || ''}
                    disabled
                    className="w-full px-3 py-2 border border-gray-200 rounded-lg bg-gray-50 text-gray-500 text-sm cursor-not-allowed"
                  />
                </div>
              </div>
            )}

            {activeTab === 'security' && (
              <div className="space-y-6">
                {/* Password Change Section */}
                <div className="space-y-4">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Change Password</h3>
                    <button
                      onClick={handlePasswordSubmit}
                      disabled={isSubmitting}
                      className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 rounded-lg transition-all disabled:text-gray-400 disabled:border-gray-100 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? 'Saving...' : 'Update Password'}
                    </button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Current Password</label>
                    <input
                      type="password"
                      name="currentPassword"
                      placeholder="Enter your current password"
                      value={formData.currentPassword}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">New Password</label>
                    <div className="mt-1">
                      <input
                        type="password"
                        name="newPassword"
                        placeholder="Enter your new password"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                      />
                      <p className="mt-2 text-sm text-gray-400">
                        8+ characters with uppercase, lowercase, number & symbol
                      </p>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Confirm New Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm your new password"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                    />
                  </div>
                </div>

                {/* MFA Section */}
                <div className="pt-6 border-t border-gray-200">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Two-Factor Authentication (2FA)</h3>
                    {!isMFAEnabled && !mfaSetupData && (
                      <button
                        onClick={handleSetupMFA}
                        disabled={isLoadingMFA}
                        className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 rounded-lg transition-all disabled:text-gray-400 disabled:border-gray-100 disabled:cursor-not-allowed"
                      >
                        {isLoadingMFA ? 'Setting up...' : 'Set up 2FA'}
                      </button>
                    )}
                  </div>
                  
                  {isMFAEnabled ? (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm text-gray-600">Two-factor authentication is enabled</p>
                          {showDisableForm && (
                            <p className="text-xs text-gray-500 mt-1">Enter your current 2FA code to disable</p>
                          )}
                        </div>
                        {!showDisableForm ? (
                          <button
                            onClick={() => setShowDisableForm(true)}
                            className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 rounded-lg transition-all"
                          >
                            Disable 2FA
                          </button>
                        ) : (
                          <div className="flex items-center gap-2">
                            <input
                              type="text"
                              value={disableCode}
                              onChange={(e) => setDisableCode(e.target.value)}
                              placeholder="Enter 2FA code"
                              className="w-32 px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                            />
                            <button
                              onClick={handleDisableMFA}
                              disabled={isLoadingMFA}
                              className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 rounded-lg transition-all disabled:text-gray-400 disabled:border-gray-100 disabled:cursor-not-allowed"
                            >
                              {isLoadingMFA ? 'Disabling...' : 'Confirm'}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      {!mfaSetupData ? (
                        <div>
                          <p className="text-sm text-gray-600">
                            Two-factor authentication adds an extra layer of security to your account by requiring a code from your authenticator app in addition to your password.
                          </p>
                        </div>
                      ) : (
                        <div className="space-y-4">
                          <div className="p-4 bg-gray-50 rounded-lg">
                            <div className="flex flex-col items-center space-y-4">
                              <QRCodeSVG 
                                value={mfaSetupData.qrCodeUrl} 
                                size={200}
                              />
                              <div className="text-center">
                                <p className="text-sm font-medium text-gray-900">Scan this QR code with your authenticator app</p>
                                <p className="text-xs text-gray-500 mt-1">or manually enter this code:</p>
                                <code className="block mt-2 p-2 bg-white rounded border border-gray-200 text-sm font-mono select-all">
                                  {mfaSetupData.secretCode}
                                </code>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <input
                              type="text"
                              value={mfaCode}
                              onChange={(e) => setMFACode(e.target.value)}
                              placeholder="Enter 6-digit code"
                              className="flex-1 px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#1e8259] focus:border-[#1e8259] text-sm"
                            />
                            <button
                              onClick={handleVerifyAndEnableMFA}
                              disabled={isLoadingMFA}
                              className="whitespace-nowrap px-4 py-2 text-sm font-medium text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 rounded-lg transition-all disabled:text-gray-400 disabled:border-gray-100 disabled:cursor-not-allowed"
                            >
                              {isLoadingMFA ? 'Verifying...' : 'Verify & Enable'}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeTab === 'team' && (
              <div className="space-y-6">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">Team Members</h3>
                  <span className="text-sm text-gray-500">
                    {teamMembers?.usage?.current || 0} / {teamMembers?.usage?.allowed || 0} members
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <div className="flex-1">
                    <div className="flex items-center flex-wrap gap-2 bg-gray-50 border border-gray-200 rounded-lg px-3 py-2">
                      {emailList.map((email, index) => (
                        <span 
                          key={index}
                          className="inline-flex items-center gap-1 px-2 py-1 bg-white border border-gray-200 rounded text-sm text-gray-600"
                        >
                          {email}
                          <button
                            onClick={() => removeEmail(index)}
                            className="text-gray-400 hover:text-gray-600"
                          >
                            ×
                          </button>
                        </span>
                      ))}
                      <input
                        type="email"
                        value={currentEmail}
                        onChange={(e) => setCurrentEmail(e.target.value)}
                        onKeyDown={handleEmailInputKeyDown}
                        placeholder={emailList.length === 0 ? "Enter email addresses separated by comma" : "Add another email"}
                        className="flex-1 min-w-[200px] text-sm bg-transparent border-0 outline-none focus:ring-0 p-0"
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleInviteSubmit}
                    disabled={isInviting || (!currentEmail?.trim() && emailList.length === 0) || (!!currentEmail && !isValidEmail(currentEmail))}
                    className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 rounded-lg transition-all disabled:text-gray-400 disabled:border-gray-100 disabled:cursor-not-allowed flex items-center gap-2"
                  >
                    {isInviting ? (
                      <>
                        <div className="animate-spin rounded-full h-4 w-4 border-2 border-gray-300 border-t-gray-600"></div>
                        <span>Inviting...</span>
                      </>
                    ) : (
                      <>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        <span>Invite</span>
                      </>
                    )}
                  </button>
                </div>

                <div className="relative space-y-3 max-h-[400px] overflow-y-auto hover:pr-2 transition-[padding] [&::-webkit-scrollbar]:hidden hover:[&::-webkit-scrollbar]:block [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-200 [&::-webkit-scrollbar-track]:bg-gray-100/50 hover:[&::-webkit-scrollbar-thumb]:bg-gray-300">
                  {teamMembers?.members?.map((member: TeamMember) => (
                    <div key={member.id} className="p-4 bg-white border border-gray-100 rounded-lg hover:border-gray-200 transition-colors">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                            <span className="text-sm font-medium text-gray-600">
                              {member.givenName?.[0] || ''}{member.familyName?.[0] || ''}
                            </span>
                          </div>
                          <div>
                            <div className="flex items-center gap-2">
                              <h4 className="text-sm font-medium text-gray-900">
                                {member.givenName} {member.familyName}
                              </h4>
                              {member.email === user?.email && (
                                <span className="text-xs text-gray-500">(You)</span>
                              )}
                            </div>
                            <p className="text-sm text-gray-500">{member.email}</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <select
                            value={member.role || 'Member'}
                            onChange={(e) => handleRoleChange(member.id, e.target.value)}
                            disabled={!isAdmin() || member.email === user?.email}
                            className="text-sm border-gray-300 rounded-md focus:ring-[#1e8259] focus:border-[#1e8259] disabled:bg-gray-50 disabled:cursor-not-allowed"
                          >
                            <option>Admin</option>
                            <option>Member</option>
                          </select>
                          {member.email !== user?.email && isAdmin() && (
                            <div className="relative">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveDropdownId(activeDropdownId === member.id ? null : member.id);
                                }}
                                className="p-2 rounded-lg hover:bg-gray-50 transition-colors team-member-dropdown"
                              >
                                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                </svg>
                              </button>

                              {activeDropdownId === member.id && (
                                <div className="fixed transform translate-x-[-272px] mt-2 w-72 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[100] team-member-dropdown">
                                  <div className="py-1">
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveMember(member.id);
                                        setActiveDropdownId(null);
                                      }}
                                      className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
                                    >
                                      <TrashIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
                                      <div className="flex-1 text-left">
                                        <p className="font-medium text-sm leading-5">Remove Member</p>
                                        <p className="text-xs text-gray-500">This action cannot be undone.</p>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
