import React, { useState, useEffect, useMemo } from 'react';
import questionnaireService from '../services/questionnaireService';
import { useNavigate } from 'react-router-dom';
import { CubeTransparentIcon, ArrowDownTrayIcon, TrashIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useReactTable, getCoreRowModel, flexRender, createColumnHelper, getPaginationRowModel } from '@tanstack/react-table';
import toast from 'react-hot-toast';

interface QuestionnaireResponse {
  id: string;
  fileName: string;
  uploadedBy: string;
  uploadDate: string;
  modifiedDate: string;
  totalQuestions: number;
  answeredQuestions: number;
  status: string;
}

interface QuestionnaireUsage {
  current: number;
  allowed: number;
  status: string;
}

interface QuestionnairesApiResponse {
  data: QuestionnaireResponse[];
  usage: QuestionnaireUsage;
}

interface Questionnaire {
  id: string;
  fileName: string;
  uploadedBy: string;
  uploadDate: string;
  modifiedDate: string;
  progress: number;
  answeredQuestions: number;
  totalQuestions: number;
  status: string;
}

interface DropdownProps {
  onExport: () => void;
  onDelete: (e: React.MouseEvent) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ActionDropdown: React.FC<DropdownProps> = ({ onExport, onDelete, isOpen, setIsOpen }) => {
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !(event.target as Element).closest('.dropdown-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen, setIsOpen]);

  return (
    <div className="relative inline-block text-left dropdown-container">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="p-2 rounded-lg hover:bg-gray-50 transition-colors"
      >
        <svg className="h-5 w-5 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="py-1">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onExport();
                setIsOpen(false);
              }}
              className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
            >
              <ArrowDownTrayIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
              <div className="flex-1 text-left">
                <p className="font-medium text-sm leading-5">Export Questionnaire</p>
                <p className="text-xs text-gray-500">Download the completed questionnaire.</p>
              </div>
            </button>

            <button
              onClick={(e) => {
                onDelete(e);
                setIsOpen(false);
              }}
              className="group flex w-full px-4 py-3 text-sm items-center hover:bg-gray-50 text-gray-700"
            >
              <TrashIcon className="w-4 h-4 mr-3 text-gray-400 group-hover:text-gray-500 shrink-0" aria-hidden="true" />
              <div className="flex-1 text-left">
                <p className="font-medium text-sm leading-5">Delete</p>
                <p className="text-xs text-gray-500">Remove this questionnaire</p>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const QuestionnaireOverview: React.FC = () => {
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [usage, setUsage] = useState<QuestionnaireUsage | null>(null);

  useEffect(() => {
    refreshQuestionnaires();
  }, []);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const refreshQuestionnaires = async () => {
    setIsLoading(true);
    try {
      const response = await questionnaireService.getQuestionnaires();
      if (!response.data) {
        setQuestionnaires([]);
        return;
      }
      
      const apiResponse = response as QuestionnairesApiResponse;
      const formattedQuestionnaires = apiResponse.data.map((q) => ({
        id: q.id,
        fileName: q.fileName,
        uploadedBy: q.uploadedBy,
        uploadDate: q.uploadDate,
        modifiedDate: q.modifiedDate,
        progress: Math.round((q.answeredQuestions / q.totalQuestions) * 100) || 0,
        answeredQuestions: q.answeredQuestions,
        totalQuestions: q.totalQuestions,
        status: q.status
      }));
      setQuestionnaires(formattedQuestionnaires);
      setUsage(apiResponse.usage);
    } catch (error) {
      console.error('Failed to fetch questionnaires:', error);
      setQuestionnaires([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      console.error('File too large. Maximum size is 10MB');
      return;
    }

    setIsUploading(true);
    try {
      const response = await questionnaireService.uploadFile(file);
      console.log('Upload successful:', response);
      await refreshQuestionnaires();
      // TODO: Add success notification
    } catch (error) {
      console.error('Upload failed:', error);
      // TODO: Add error notification
    } finally {
      setIsUploading(false);
    }
  };

  const handleQuestionnaireClick = (questionnaire: Questionnaire, event: React.MouseEvent) => {
    // Check if clicking on menu or its children
    const target = event.target as HTMLElement;
    if (target.closest('.menu-button') || target.closest('[role="menu"]')) {
      return;
    }
    
    // Check subscription status first
    if (usage?.status === 'canceled') {
      toast.error('Your subscription has expired. Please renew your subscription.');
      return;
    }

    // Then check questionnaire limit
    if (usage && usage.current >= usage.allowed) {
      toast.error('You have reached your questionnaire limit. Please upgrade your plan.');
      return;
    }
    
    // Route based on status with forceRefresh state parameter
    if (questionnaire.status === 'MAPPED') {
      navigate(`/questionnaire/${questionnaire.id}/edit`, { 
        state: { forceRefresh: true } 
      });
    } else {
      navigate(`/qmapping/${questionnaire.id}`);
    }
  };

  const handleExport = async (questionnaire: Questionnaire) => {
    try {
      toast.loading('Exporting questionnaire...');
      const response = await questionnaireService.exportQuestionnaire(questionnaire.id);
      
      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = response.downloadUrl;
      link.download = questionnaire.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.dismiss();
      toast.success('Export successful');
    } catch (error) {
      console.error('Export failed:', error);
      toast.dismiss();
      toast.error('Failed to export questionnaire');
    }
  };

  // Add the delete handler
  const handleDelete = async (questionnaire: Questionnaire) => {
    try {
      
      await questionnaireService.deleteQuestionnaire(questionnaire.id);
      await refreshQuestionnaires();  // Refresh the list after deletion
      toast.dismiss();
      toast.success('Questionnaire deleted');
    } catch (error) {
      console.error('Delete failed:', error);
      toast.dismiss();
      toast.error('Failed to delete questionnaire.');
    }
  };

  // Define columns
  const columnHelper = createColumnHelper<Questionnaire>();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('fileName', {
        header: 'File Name',
        cell: (info) => (
          <div className="flex items-center">
            <div className="text-sm font-medium text-gray-900">{info.getValue()}</div>
          </div>
        ),
      }),
      columnHelper.accessor('uploadedBy', {
        header: 'Uploaded By',
        cell: (info) => <span className="text-sm text-gray-500">{info.getValue()}</span>,
      }),
      columnHelper.accessor('modifiedDate', {
        header: 'Last Modified',
        cell: (info) => (
          <span className="text-sm text-gray-500">
            {formatDate(info.getValue())}
          </span>
        ),
      }),
      columnHelper.accessor('progress', {
        header: 'Progress',
        cell: (info) => {
          const questionnaire = info.row.original;
          
          if (questionnaire.status !== 'MAPPED') {
            return (
              <span className="text-sm text-gray-500 whitespace-nowrap">
                Pending Mapping
              </span>
            );
          }

          const isComplete = questionnaire.answeredQuestions === questionnaire.totalQuestions;

          return (
            <div className="flex items-center gap-3">
              <div className="flex-grow w-24 h-1.5 bg-gray-100 rounded-full overflow-hidden">
                <div 
                  className={`h-full rounded-full transition-all duration-300 ${
                    isComplete 
                      ? 'bg-[#1e8259]' 
                      : 'bg-gradient-to-r from-[#1e8259]/70 to-[#1e8259]'
                  }`}
                  style={{ width: `${(questionnaire.answeredQuestions / questionnaire.totalQuestions) * 100}%` }}
                />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500 whitespace-nowrap">
                  {questionnaire.answeredQuestions} of {questionnaire.totalQuestions}
                </span>
                {isComplete && (
                  <CheckCircleIcon className="w-5 h-5 text-[#1e8259]" />
                )}
              </div>
            </div>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <div className="text-right">Actions</div>,
        cell: ({ row }) => (
          <div className="text-right">
            <ActionDropdown
              onExport={() => handleExport(row.original)}
              onDelete={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete(row.original);
              }}
              isOpen={activeDropdownId === row.original.id}
              setIsOpen={(isOpen) => setActiveDropdownId(isOpen ? row.original.id : null)}
            />
          </div>
        ),
      }),
    ],
    []
  );

  // Add memoized filtered questionnaires (add this after the searchTerm state)
  const filteredQuestionnaires = useMemo(() => 
    questionnaires.filter(questionnaire => {
      const matchesSearch = questionnaire.fileName.toLowerCase()
        .includes(searchTerm.toLowerCase());
      return matchesSearch;
    }),
    [questionnaires, searchTerm]
  );

  // Update the table initialization to use filtered data
  const table = useReactTable({
    data: filteredQuestionnaires, // Changed from questionnaires to filteredQuestionnaires
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <>
      {/* Adjust the search header alignment */}
      <div className="fixed top-0 left-64 right-0 bg-white z-30">
        <div className="px-6">
          <div className="max-w-7xl mx-auto">
            <div className="flex h-16 items-center">
              <div className="flex flex-1 items-center">
                <div className="relative flex-1 max-w-2xl">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg 
                      className="h-4 w-4 text-gray-400" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={1.5} 
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="block w-full py-1.5 pl-10 pr-4 text-sm text-gray-900 
                      placeholder:text-gray-400 focus:outline-none focus:ring-0
                      bg-gray-50/50 focus:bg-white transition-colors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add spacing for fixed header */}
      <div className="h-16" />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg px-4 sm:px-6 lg:px-8">
          {/* Header Section - Further reduced spacing */}
          <div className="pt-1 pb-2">
            <div className="flex justify-between items-center mb-3">
              <div>
                <h1 className="text-xl font-medium text-gray-900">Questionnaire Overview</h1>
                <p className="mt-0.5 text-sm text-gray-500">
                  Manage and track your questionnaire progress
                </p>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-sm text-gray-500">
                  {usage?.current || 0} / {usage?.allowed || 0} questionnaires
                </span>
                <button
                  onClick={() => {
                    if (isUploading) return;
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.xlsx';
                    input.onchange = (e) => {
                      const file = (e.target as HTMLInputElement).files?.[0];
                      if (file) handleFileUpload({ target: { files: [file] } } as any);
                    };
                    input.click();
                  }}
                  disabled={isUploading}
                  className="ml-3 inline-flex items-center px-3 py-2 border border-transparent 
                    text-sm font-medium rounded-md shadow-sm text-white bg-[#1e8259] 
                    hover:bg-[#1e8259]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 
                    focus:ring-[#1e8259] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isUploading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Uploading...
                    </>
                  ) : (
                    <>
                      <svg 
                        className="w-4 h-4 mr-2 text-current"
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        strokeWidth={2}
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                        />
                      </svg>
                      Import Questionnaire
                    </>
                  )}
                </button>
              </div>
            </div>

            {/* Stats cards */}
            <div className="grid grid-cols-3 gap-6 mb-6">
              <div className="bg-white rounded-xl p-6 border border-gray-200 hover:border-gray-300 transition-colors">
                <div className="text-3xl font-bold text-gray-900">{filteredQuestionnaires.length}</div>
                <div className="mt-1 text-sm font-medium text-gray-500">Total Questionnaires</div>
              </div>
              
              <div className="bg-white rounded-xl p-6 border border-gray-200 hover:border-gray-300 transition-colors">
                <div className="text-3xl font-bold text-gray-900">
                  {filteredQuestionnaires.filter(q => q.progress < 100).length}
                </div>
                <div className="mt-1 text-sm font-medium text-gray-500">In Progress</div>
              </div>
              
              <div className="bg-white rounded-xl p-6 border border-gray-200 hover:border-gray-300 transition-colors">
                <div className="text-3xl font-bold text-gray-900">
                  {filteredQuestionnaires.filter(q => q.progress === 100).length}
                </div>
                <div className="mt-1 text-sm font-medium text-gray-500">Completed</div>
              </div>
            </div>
          </div>

          {/* Table section */}
          <div className=" rounded-lg overflow-visible">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {!isLoading && (filteredQuestionnaires.length > 0 ? (
                  table.getRowModel().rows.map(row => (
                    <tr 
                      key={row.id} 
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={(e) => handleQuestionnaireClick(row.original, e)}
                    >
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className="px-6 py-4">
                          {cell.column.id === 'actions' ? (
                            <div className="text-right">
                              <ActionDropdown
                                onExport={() => handleExport(row.original)}
                                onDelete={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDelete(row.original);
                                }}
                                isOpen={activeDropdownId === row.original.id}
                                setIsOpen={(isOpen) => setActiveDropdownId(isOpen ? row.original.id : null)}
                              />
                            </div>
                          ) : (
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="px-6 py-24 text-center">
                      <div className="max-w-lg mx-auto">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
                          <CubeTransparentIcon className="h-6 w-6 text-gray-600" />
                        </div>
                        <h3 className="text-base font-medium text-gray-900 mb-8">Get Started with Questionnaires</h3>
                        
                        <div className="space-y-6">
                          {/* Step 1 */}
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">1</span>
                              </div>
                            </div>
                            <div className="flex-1 text-left">
                              <p className="text-sm text-gray-900 font-medium">Build Knowledge Base</p>
                              <p className="text-sm text-gray-500">Import your completed questionnaires to train the AI</p>
                            </div>
                          </div>

                          {/* Step 2 */}
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">2</span>
                              </div>
                            </div>
                            <div className="flex-1 text-left">
                              <p className="text-sm text-gray-900 font-medium">Import New Questionnaire</p>
                              <p className="text-sm text-gray-500">Upload the questionnaire you need help with</p>
                            </div>
                          </div>

                          {/* Step 3 */}
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">3</span>
                              </div>
                            </div>
                            <div className="flex-1 text-left">
                              <p className="text-sm text-gray-900 font-medium">Review & Export</p>
                              <p className="text-sm text-gray-500">Verify AI-generated answers and download your completed questionnaire</p>
                            </div>
                          </div>
                        </div>

                        {/* Import Button */}
                        <div className="mt-8">
                          <button
                            onClick={() => {
                              if (isUploading) return;
                              const input = document.createElement('input');
                              input.type = 'file';
                              input.accept = '.xlsx';
                              input.onchange = (e) => {
                                const file = (e.target as HTMLInputElement).files?.[0];
                                if (file) handleFileUpload({ target: { files: [file] } } as any);
                              };
                              input.click();
                            }}
                            disabled={isUploading}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 
                              text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50
                              disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {isUploading ? (
                              <>
                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-700" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                </svg>
                                Uploading...
                              </>
                            ) : (
                              <>
                                <svg 
                                  className="w-4 h-4 mr-2 text-current"
                                  xmlns="http://www.w3.org/2000/svg" 
                                  fill="none" 
                                  viewBox="0 0 24 24" 
                                  strokeWidth={2}
                                  stroke="currentColor"
                                >
                                  <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                                  />
                                </svg>
                                Import Questionnaire
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex items-center gap-4">
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <span className="font-medium">{table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}</span>
                    {' '}-{' '}
                    <span className="font-medium">
                      {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, filteredQuestionnaires.length)}
                    </span>
                    {' '}of{' '}
                    <span className="font-medium">{filteredQuestionnaires.length}</span>
                    {' '}results
                  </p>
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value));
                    }}
                    className="text-sm border-gray-300 rounded-md focus:ring-[#1e8259] focus:border-[#1e8259]"
                  >
                    {[10, 20, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                    <button
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    >
                      Previous
                    </button>
                    
                    {/* Page Numbers */}
                    {Array.from(
                      { length: table.getPageCount() },
                      (_, index) => {
                        if (
                          index === 0 ||
                          index === table.getPageCount() - 1 ||
                          Math.abs(index - table.getState().pagination.pageIndex) <= 1
                        ) {
                          return (
                            <button
                              key={index}
                              onClick={() => table.setPageIndex(index)}
                              className={`relative inline-flex items-center px-2 py-2 border text-sm font-medium text-gray-500
                                ${index === table.getState().pagination.pageIndex
                                  ? 'z-10 bg-gray-50 border-gray-300 font-semibold'
                                  : 'bg-white border-gray-300 hover:bg-gray-50'
                                }`}
                            >
                              {index + 1}
                            </button>
                          );
                        }
                        if (Math.abs(index - table.getState().pagination.pageIndex) === 2) {
                          return (
                            <span
                              key={index}
                              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500"
                            >
                              ...
                            </span>
                          );
                        }
                        return null;
                      }
                    )}

                    <button
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
