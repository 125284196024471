import { ApiGatewayService } from './apiGatewayService';

const api = new ApiGatewayService(import.meta.env.VITE_API_URL || '');

export interface TeamMember {
  id: string;
  email: string;
  givenName: string;
  familyName: string;
  role: string;
}

class TeamService {
  async getTeamMembers() {
    const response = await api.request({
      method: 'GET',
      url: '/api/team/members',
    });
    return response.data;
  }

  async updateMemberRole(userId: string, role: string) {
    const response = await api.request({
      method: 'PUT',
      url: `/api/team/members/${userId}/role`,
      data: { role }
    });
    return response.data;
  }

  async inviteMember(email: string, role: string) {
    try {
      const response = await api.request({
        method: 'POST',
        url: '/api/team/invite',
        data: { email, role }
      });
      if (response.status >= 400) {
        throw new Error(response.data.message || response.data.error || 'Failed to invite team member');
      }
      return response.data;
    } catch (error: any) {
      console.error('TeamService error:', error);
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw new Error(error.message || 'Failed to invite team member');
    }
  }

  async removeMember(userId: string) {
    const response = await api.request({
      method: 'DELETE',
      url: `/api/team/members/${userId}`,
    });
    return response.data;
  }
}

export default new TeamService(); 